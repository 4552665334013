<template>
	<div>
		<!-- 编辑项目信息 -->
		<validation-observer ref="add_editForm" #default="{ handleSubmit }">
			<b-form @submit.prevent="handleSubmit(editConfirm)">
				<b-row>
					<b-col class="pb-50" cols="12" md="6">
						<b-form-group :label="$t('projectName')" label-for="project_name">
							<b-form-input id="project_name" v-model="blankProjectData.project_name" name="project_name" />
						</b-form-group>
					</b-col>
					<b-col class="pb-50" cols="12" md="6">
						<b-form-group :label="$t('englishName')" label-for="project_en_name">
							<b-form-input id="project_en_name" v-model="blankProjectData.project_en_name" name="project_en_name" />
						</b-form-group>
					</b-col>
					<b-col class="pb-50" cols="12" md="6">
						<b-form-group :label="$t('Images')" label-for="images">
							<b-row>
								<b-col class="d-flex" cols="10">
									<div v-for="(image, index) in blankProjectData.images" :key="'project_images_' + index" class="position-relative overflow-hidden" style="width: 30%; padding: 0.2rem; max-height: 12rem">
										<b-button class="position-absolute" size="sm" style="right: 0.2rem; padding: 0.1rem" variant="danger" @click="deleteImages(index)">
											<feather-icon class="text-bold" icon="XIcon" size="16" />
										</b-button>
										<b-img :src="image" alt="Image 1" fluid rounded></b-img>
									</div>
								</b-col>
								<b-col cols="2">
									<b-button size="sm" style="margin: 0.2rem" variant="primary" @click="$refs.refInputEl.$el.click()">
										<b-form-file ref="refInputEl" :hidden="true" accept=".jpg, .png, .gif" plain @input="uploadProjectImages" />
										<feather-icon icon="UploadIcon" size="14" />
										<span style="margin-left: 0.4rem">{{ $t("Update") }}</span>
									</b-button>

									<p class="small mt-50 text-muted">
										图片格式：JPG/GIF/PNG <br />
										最大尺寸：1M
									</p>
								</b-col>
							</b-row>
						</b-form-group>
					</b-col>
					<b-col class="pb-50" cols="12" md="6">
						<b-form-group :label="$t('Project Vr')" label-for="vr">
							<b-input-group class="input-group-merge">
								<b-input-group-prepend is-text>
									<feather-icon icon="ImageIcon" />
								</b-input-group-prepend>
								<b-form-input id="vr" v-model="blankProjectData.vr" type="text" />
							</b-input-group>
						</b-form-group>
					</b-col>

					<b-col class="pb-50" cols="12" md="12">
						<b-form-group :label="$t('Description')" label-for="description">
							<b-form-textarea id="description" v-model="blankProjectData.description" rows="5"></b-form-textarea>
						</b-form-group>
					</b-col>
				</b-row>
				<hr class="mt-1 mb-2" />
				<b-row>
					<!--					设置项目主页-->
					<b-col class="pb-50" cols="12" md="3">
						<b-form-group :label="$t('homepage_type')" label-for="project_homepage_type">
							<v-select id="project_homepage_type" v-model="blankProjectData.homepage_type" :clearable="false" :options="homepage_type_list" :reduce="(text) => text.value" label="text" />
						</b-form-group>
					</b-col>

					<b-col class="pb-50" cols="12" md="9">
						<b-form-group v-show="blankProjectData.homepage_type === 'cuntom'" :label="$t('homepage_path') + '（ 前部加 https:// 或 http:// 为外部链接 ）'" label-for="project_homepage_path">
							<b-input-group class="input-group-merge">
								<b-input-group-prepend is-text>
									<feather-icon icon="GlobeIcon" />
								</b-input-group-prepend>
								<b-form-input id="project_homepage_path" v-model="blankProjectData.homepage_path" type="text" />
							</b-input-group>
						</b-form-group>
					</b-col>
				</b-row>
				<hr class="mt-1 mb-2" />
				<b-row>
					<!--					基础信息-->
					<b-col class="pb-50" cols="12" md="3">
						<b-form-group :label="$t('Project Type')" label-for="building_type">
							<v-select v-model="blankProjectData.building_type" :clearable="false" :options="building_type_list" :reduce="(text) => text.value" label="text" />
						</b-form-group>
					</b-col>
					<b-col class="pb-50" cols="12" md="3">
						<b-form-group :label="$t('Project Level')" label-for="building_level">
							<b-input-group append="层" class="input-group-merge">
								<b-form-input id="building_level" v-model="blankProjectData.building_level" />
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col class="pb-50" cols="12" md="3">
						<b-form-group :label="$t('Project Area')" label-for="building_area">
							<b-input-group append="m2" class="input-group-merge">
								<b-form-input id="building_area" v-model="blankProjectData.building_area" type="text" />
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col class="pb-50" cols="12" md="3">
						<b-form-group :label="$t('Project Time')" label-for="building_time">
							<b-form-input id="building_time" v-model="blankProjectData.building_time" type="date" />
						</b-form-group>
					</b-col>

					<!--					地址信息-->
					<b-col class="pb-50" cols="12" md="3">
						<b-form-group :label="$t('Project City')" label-for="city">
							<b-input-group class="input-group-merge">
								<b-form-input id="city" v-model="blankProjectData.city" type="text" />
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col class="pb-50" cols="12" md="3">
						<b-form-group :label="$t('lat')" label-for="latitude">
							<b-input-group append="°" class="input-group-merge">
								<b-form-input id="lat" v-model="blankProjectData.lat" />
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col class="pb-50" cols="12" md="3">
						<b-form-group :label="$t('lng')" label-for="longitude">
							<b-input-group append="°" class="input-group-merge">
								<b-form-input id="lng" v-model="blankProjectData.lng" />
							</b-input-group>
						</b-form-group>
					</b-col>

					<b-col class="pb-50" cols="12" md="3">
						<b-form-group :label="$t('project_add')" label-for="project_add">
							<b-input-group class="input-group-merge">
								<b-input-group-prepend is-text>
									<feather-icon icon="MapPinIcon" />
								</b-input-group-prepend>
								<b-form-input id="project_add" v-model="blankProjectData.address" type="text" />
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col class="pb-50" cols="12" md="3">
						<b-form-group :label="$t('project_tel')" label-for="project_tel">
							<b-input-group class="input-group-merge">
								<b-input-group-prepend is-text>
									<feather-icon icon="PhoneIcon" />
								</b-input-group-prepend>
								<b-form-input id="project_tel" v-model="blankProjectData.telephone" type="text" />
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col class="pb-50" cols="12" md="3">
						<b-form-group :label="$t('project_fax')" label-for="project_fax">
							<b-input-group class="input-group-merge">
								<b-input-group-prepend is-text>
									<feather-icon icon="PrinterIcon" />
								</b-input-group-prepend>
								<b-form-input id="project_fax" v-model="blankProjectData.fax" type="text" />
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col class="pb-50" cols="12" md="3">
						<b-form-group :label="$t('project_email')" label-for="project_email">
							<b-input-group class="input-group-merge">
								<b-input-group-prepend is-text>
									<feather-icon icon="MailIcon" />
								</b-input-group-prepend>
								<b-form-input id="project_email" v-model="blankProjectData.email" type="text" />
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col class="pb-50" cols="12" md="3">
						<b-form-group :label="$t('project_web')" label-for="project_web">
							<b-input-group class="input-group-merge">
								<b-input-group-prepend is-text>
									<feather-icon icon="GlobeIcon" />
								</b-input-group-prepend>
								<b-form-input id="project_web" v-model="blankProjectData.web" type="text" />
							</b-input-group>
						</b-form-group>
					</b-col>
				</b-row>
				<hr class="mt-1 mb-2" />
				<div class="mt-1">
					<b-button class="mr-1" size="sm" type="submit" variant="primary">
						<feather-icon icon="SaveIcon" />
						<span class="ml-1">{{ $t("Save Change") }}</span>
					</b-button>
					<b-button size="sm" type="reset" variant="outline-secondary" @click="resetButton">
						<feather-icon icon="RefreshCcwIcon" />
						<span class="ml-1">{{ $t("Reset") }}</span>
					</b-button>
				</div>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
	import store from "@/store";
	import flatPickr from "vue-flatpickr-component";
	import { reactive, toRefs } from "@vue/composition-api";
	import vSelect from "vue-select";
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import { showToast } from "@/libs/utils/showToast";
	import router from "@/router";
	import { cos_upload } from "@/libs/utils/uploadFiles";
	import { showConfirm } from "@/libs/utils/showConfirm";

	export default {
		components: {
			flatPickr,
			vSelect,
			ValidationObserver,
			ValidationProvider,
		},
		props: {
			projectData: [Object],
		},
		setup(props, { refs }) {
			const blankProjectData = {
				project_id: 0,
				project_name: "",
				project_data: "",
				homepage_type: "simple",
				homepage_path: "",
				lat: "",
				lng: "",
				description: "",
				building_type: "",
				building_area: "",
				building_level: "",
				city: "",
				address: "",
				telephone: "",
				fax: "",
				email: "",
				web: "",
			};

			const event = reactive({
				blankProjectData: JSON.parse(JSON.stringify(props.projectData)),
				building_type_list: [
					{ text: "办公", value: "办公" },
					{ text: "住宅", value: "住宅" },
					{ text: "综合", value: "综合" },
					{ text: "工业", value: "工业" },
					{ text: "其他", value: "其他" },
				],
				refInputEl: null,
				homepage_type_list: [
					{ text: "简略信息", value: "simple" },
					{ text: "详细信息", value: "complex" },
					{ text: "仪表盘", value: "dashboard" },
					// {text: "自定义", value: 'custom'},
				],
			});

			const editConfirm = () => {
				refs.add_editForm.validate().then((success) => {
					if (success) {
						if (props.projectData == null) {
							store
								.dispatch("projects/addProject", event.blankProjectData)
								.then((response) => {
									console.log(response);
									if (response) {
										showToast(null, "添加完成", `您已成功添加项目`);
										router.push({
											path: "/admin/projects/list",
										});
									}
								})
								.catch((error) => {
									showToast(error);
								});
						} else {
							store
								.dispatch("projects/updateProject", event.blankProjectData)
								.then((response) => {
									if (response) {
										showToast(null, "修改完成", `您已成功修改该项目的基本信息`);
									}
									router.push({
										path: "/admin/projects/list",
									});
								})
								.catch((error) => {
									showToast(error);
								});
						}
					}
				});
			};
			const resetButton = () => {
				event.blankProjectData = blankProjectData;
			};

			const uploadProjectImages = () => {
				const file = event.refInputEl.files[0];
				cos_upload(
					file,
					(progress) => {
						console.log("progress", progress);
					},
					(res) => {
						console.log("success", res);
						event.blankProjectData.images.push("https://" + res.Location);
					},
					"uploads/projects/" + event.blankProjectData.project_id
				);
			};

			const deleteImages = (index) => {
				showConfirm("确认删除此图片？", () => {
					event.blankProjectData.images.splice(index, 1);
				});
			};
			return {
				...toRefs(event),
				resetButton,
				editConfirm,
				uploadProjectImages,
				deleteImages,
			};
		},
	};
</script>

<style lang="scss">
	@import "src/@core/scss/vue/libs/vue-flatpicker.scss";
</style>
