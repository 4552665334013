<template>
	<div :style="{ 'min-height': '200px' }">
		<assigned-users :has-user-list="hasUserList" :is-asigned-user-sidebar-active.sync="isAsignedUserSidebarActive" :project_id="project_id" :role-options="roleOptions" @isAssign="getList" @refetch-data="refetchProjectData" />

		<!-- 分配用户 -->
		<div class="text-right">
			<b-button size="sm" variant="primary" @click="clickbtn">
				<feather-icon icon="UserPlusIcon" />
				<span class="text-nowrap ml-1">{{ $t("AssignedUser") }}</span>
			</b-button>
		</div>

		<!-- Form: Personal Info Form -->
		<b-table
			:empty-text="$t('No Data')"
			:fields="$t('tableColumnsProUser')"
			:items="data.users"
			:sort-by.sync="sortBy"
			:sort-desc.sync="isSortDirDesc"
			class="position-relative table mt-2"
			primary-key="id"
			responsive
			show-empty
			style="table-layout: fixed; word-break: break-all"
		>
			<template #cell(order)="data">
				<div class="text-center">
					<span>{{ data.index + 1 }}</span>
				</div>
			</template>

			<template #cell(user_avatar)="data">
				<b-media vertical-align="center">
					<template #aside>
						<b-avatar :src="data.item.user_avatar" :text="avatarText(data.item.user_name)" size="32" variant="primary" />
					</template>
				</b-media>
			</template>

			<template #cell(user_name)="data">
				<col :style="{ width: '10px' }" />
				<div>
					{{ data.item.user_name }}
				</div>
			</template>

			<!-- Column: Role -->
			<!-- 修改角色 -->
			<template #cell(role)="data">
				<div class="text-nowrap">
					<b-form-group class="mb-0">
						<b-form-select
							v-model="data.item.role.id"
							@change="
								(value) => {
									updateRole(value, data);
								}
							"
						>
							<b-form-select-option v-for="(item, index) in roleOptions" :key="index" :value="item.id">
								{{ item.name }}
							</b-form-select-option>
						</b-form-select>
					</b-form-group>
				</div>
			</template>

			<template #cell(action)="data">
				<div class="align-middle text-center">
				  <b-button class="btn-icon" size="sm" variant="danger" @click="deleteUserRole(data.item.attach_id)">
						<feather-icon icon="Trash2Icon" />
					</b-button>
				</div>
			</template>
		</b-table>
	</div>
</template>
<script>
	import { avatarText } from "@core/utils/filter";
	import vSelect from "vue-select";
	import store from "@/store";
	import { getCurrentInstance, ref } from "@vue/composition-api";
	import AssignedUsers from "./AssignedUser.vue";

	export default {
		components: {
			vSelect,
			AssignedUsers,
		},
		props: {
			projectData: [Object, null],
		},
		data() {
			return {
				data: JSON.parse(JSON.stringify(this.projectData)),
				roleOptions: [],
				hasUserList: [],
				userList: [],
				list: "",
			};
		},
		mounted() {
			for (let item of this.data.users) {
				this.hasUserList.push(item.user_id);
			}
			store
				.dispatch("projects/getRole", {
					project_id: this.projectData.project_id,
				})
				.then((response) => {
				  let roleOptions = JSON.parse(JSON.stringify(response.data));
				  this.roleOptions = roleOptions.filter(item => item.pid == this.projectData.project_id);
				  console.log('roleOptions', this.roleOptions)
				});
		},
		methods: {
			updateRole(value, data) {
				console.log(data.item.attach_id, value);
				this.$showConfirm(
					"确认更改?",
					() => {
						store
							.dispatch("projects/changeRole", {
								attach_id: data.item.attach_id,
								role_id: value,
							})
							.then((response) => {
								if (response) {
									this.$showToast(null, "更改完成", "您已成功更改该角色");
								}
							})
							.catch((error) => {
								if (error.response.status === 404) {
									console.log(error);
								}
							});
					},
					// 如果用户点击取消
					() => {
						data.item.role.id = this.userData.projects[data.index].role.id;
					}
				);
			},
		  deleteUserRole(id) {
				this.$showConfirm("确认删除?", () => {
					store
							.dispatch("users/detachProject", {attach_id: id})
						.then((response) => {
							if (response) {
							  this.$showToast(null, "删除完成", "您已成功删除该用户权限")
							}
						})

				});
			},
			getList(data) {
				this.list = data;
			  console.log('user_list', this.list);
			},
			clickbtn() {
				if (this.list instanceof Array) {
					if (this.list.length === 0) {
						this.$showToast(null, "不能再添加了", "所有用户都在项目里啦");
					} else {
						this.isAsignedUserSidebarActive = true;
					}
				} else {
					this.$showToast(null, "请稍等");
				}
			},
		},
		watch: {
			"data.users": function (val) {
				if (val) {
					console.log("触发 watch data.users");
					this.hasUserList = [];
					for (let item of this.data.users) {
						this.hasUserList.push(item.user_id);
					}
				}
			},
		},
		setup(props) {
			const instance = getCurrentInstance().proxy;
			const sortBy = ref("id");
			const isSortDirDesc = ref(true);
			const project_id = props.projectData.project_id;
			const isAsignedUserSidebarActive = ref(false);
			const refetchProjectData = function () {
				store
					.dispatch("projects/fetchProject", props.projectData.project_id)
					.then((response) => {
						instance.data = response.data.data;
					})
					.catch((error) => {
						if (error.response.status === 404) {
							// projectData.value = undefined;
						}
					});
			};
			return {
				avatarText,
				sortBy,
				isSortDirDesc,
				project_id,
				refetchProjectData,
				isAsignedUserSidebarActive,
			};
		},
	};
</script>

<style lang="scss">
	@import "src/@core/scss/vue/libs/vue-select.scss";

	.table th:nth-last-child(1) {
		text-align: center;
	}
</style>
